import {DeleteForeverRounded} from "@mui/icons-material";
import {Box, CircularProgress, Button, IconButton, styled, SvgIcon} from "@mui/material";
import {ComponentPropsWithoutRef, MouseEvent, useCallback, useRef, useState} from "react";
import {useDropzone} from "react-dropzone";
import * as api from '../../Api';

const Styled = styled(Box)({
        padding: 24,
        borderRadius: 10,
        overflow: 'hidden',
        position: 'relative',
        border: '1px dashed rgba(100,100,100,0.15)',
        textAlign: 'center',
        cursor: 'pointer',
        color: 'rgba(120,120,120,1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(100,100,100,.05)',
        ['&:hover']: {
            backgroundColor: 'rgba(100,100,100,.15)',
        }
    });

const StyledOverlay = styled(Box)({
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: 'rgba(250,250,250,0.9)',
    zIndex: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: 'common.main',
});

const StyledProgress = styled('div')(({ progress }: { progress: number}) => ({
    width: `${progress}%`,
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 127, 255, 0.1)',
    transition: 'width 160ms'
}))

const LoadingOverlay = ({ progress, children }: { progress: number } & ComponentPropsWithoutRef<typeof StyledOverlay>) => {
    return (
        <StyledOverlay>
            <StyledProgress progress={progress}/>
            <Box sx={{ zIndex: 5 }}>
                {children}
            </Box>
        </StyledOverlay>
    )
}

const getDuration = async (file: File): Promise<number> => {
    return new Promise(((resolve, reject) => {
        const urlObj = URL.createObjectURL(file);

        const audio = new Audio();

        audio.addEventListener("load", () => {
            URL.revokeObjectURL(urlObj);
        });

        audio.addEventListener("loadedmetadata", () => {
            resolve(Math.round(audio.duration))
        });

        audio.addEventListener("error", (err) => {
            reject(err)
        });

        audio.src = urlObj;
    }))

}

interface IDropzoneProps {
    dragLabel?: string,
    id?: string,
    fileType: 'track' | 'artwork',
    Icon: typeof SvgIcon
    onSuccess: (...args: any[]) => void,
    onUploadStatusChange: (state: boolean) => void,
    onDelete: () => void
}

export const Dropzone = ({ children, Icon, id, dragLabel = 'fil', onSuccess, onDelete, onUploadStatusChange, fileType }: ComponentPropsWithoutRef<typeof Box> & IDropzoneProps) => {

    const [ loading, setLoading ] = useState<string | null>(null);
    const [ hideChildren, setHideChildren ] = useState(false);
    const [ uploadProgress, setUploadProgress ] = useState(0);
    const cancelRequestRef = useRef<AbortController>(new AbortController())

    const onDrop = useCallback(async (acceptedFiles: any) => {

        setLoading(`Uploader ${dragLabel}`);
        onUploadStatusChange(true);

        const formData = new FormData();
        formData.append(
            "nspAsset",
            acceptedFiles[0],
            acceptedFiles[0].name
        );

        let query: { [key: string]: string | number } = { id: id! };

        if (fileType === 'track') {
            query.duration = await getDuration(acceptedFiles[0])
        }

        const { url } = await api.upload(fileType, formData, query,
            (progress) => {
                if (progress < 100) {
                    setLoading(`Uploader ${progress}%`);
                    setUploadProgress(progress)
                } else {
                    setLoading(`Behandler...`)
                }
            }, cancelRequestRef.current);

        console.log(url);

        if (url) {
            const { duration } = query ?? {};
            onSuccess({url, duration}, fileType)
        }

        setHideChildren(false);
        setLoading(null)


    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, maxFiles: 1})

    const handleDelete = async (evt: MouseEvent) => {
        evt.stopPropagation();
        setHideChildren(true);
        await api.deleteUpload(fileType, id!);
        onDelete()
    }

    const handleCancelUpload = (evt: MouseEvent) => {
        evt.stopPropagation();
        cancelRequestRef.current?.abort();
        onUploadStatusChange(false);
        cancelRequestRef.current = new AbortController();
    }

    return (

        <Styled sx={{marginTop: 2}} {...getRootProps()}>

            {
                Boolean(loading) && (
                    <LoadingOverlay progress={uploadProgress}>
                        <CircularProgress size={32}/>
                        <label style={{ display: 'block'}}>{loading}</label>
                        <Button size={'small'} onClick={handleCancelUpload} color={'error'}>
                            Annullér
                        </Button>
                    </LoadingOverlay>
                )
            }

            {
                children && !hideChildren && (
                    <IconButton onClick={handleDelete} color={'error'} sx={{ position: 'absolute', top: 8, right: 8}}>
                        <DeleteForeverRounded/>
                    </IconButton>
                )
            }
            <input {...getInputProps()} />
            { (!children || hideChildren) && <Icon fontSize={'large'} sx={{ marginBottom: 1}}/> }
            {
                children && !hideChildren ? (
                    children
                ) : isDragActive ?
                    <p>Træk {dragLabel} hertil...</p> :
                    <p>Træk {dragLabel} herind eller tryk for at vælge</p>
            }
        </Styled>

    )
}

