import create from 'zustand';
import { persist } from "zustand/middleware";

export const useStore = create(persist<{ isAuthenticated: boolean, setIsAuthenticated: (value: boolean) => void}>(
    (set, get) => ({
        isAuthenticated: false,
        setIsAuthenticated: (value: boolean) => set({ isAuthenticated: value })
    }),
    {
        name: "nsp-admin",
        getStorage: () => sessionStorage,
    }
))
