import {
    Box,
    Container,
    styled,
    Stack,
    Button, useMediaQuery
} from "@mui/material";
import {ReactNode, useEffect, useRef, useState} from "react";
import {useMatch, useNavigate} from "react-router-dom";

import {
    CollectionsRounded,
    PeopleRounded,
    ExitToApp
} from "@mui/icons-material";

import {TabBar, TTab} from "../TabBar/TabBar";
import {useAuth} from "../../Hooks/useAuth";

const Styled = styled('main')({
    height: '100vh',
})

const StyledHeader = styled(Box)({
    width: '100%',
    padding: '40px 40px 0 40px',
    //boxShadow: '9px 5px 11px 0 rgba(226,226,226,0.25)',
    fontSize: '40px',
    zIndex: 1,
    display: 'flex',

    alignItems: 'center',
    backgroundColor: 'transparent',
    //borderBottom: '1px solid #E2E2E2',
})

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center'
})
const fakeTabs: Array<TTab> = [{
    key: 'sessions',
    label: 'Sessioner',
    Icon: CollectionsRounded
},{
    key: 'users',
    label: 'Brugere',
    Icon: PeopleRounded
}/*,{
    key: 'settings',
    label: 'Indstillinger',
    Icon: SettingsRounded
}*/];

const StyledTabBarContainer = styled(Box)({
    display: 'inline-flex',
    position: 'sticky',
    top: 8,
    zIndex: 5,
    ['@media screen and (max-width: 699px)']: {
    }
})

export const AppShell = ({ children }: { children: ReactNode }) => {
    const headerRef = useRef<HTMLElement | null>(null);
    const navRef = useRef<HTMLElement | null>(null);
    const [ headerHeight, setHeaderHeight ] = useState(88);
    const [ navHeight, setNavHeight ] = useState(88);
    const { signOut } = useAuth();

    const isMobile = useMediaQuery('(max-width:699px)');
    const { params: { id: locationKey } } = useMatch(':id/*') ?? { params: {}};

    useEffect(() => {
        const h = headerRef.current?.clientHeight ?? 88;
        const n = navRef.current?.clientWidth ?? 300;
        setHeaderHeight(h)
        setNavHeight(n)
    }, [])

    const navigate = useNavigate();

    const handleChangeTab = (key: string) => {
        navigate(`/${key}`)
    }

    const isLogin = locationKey === 'login';

    return (
        <Styled>
            <StyledHeader
                padding={isLogin ? '40px 40px 0 40px' : '40px'}
                justifyContent={isLogin ? 'center' : 'space-between'}
                position={isMobile ? 'relative' : 'fixed'}
                ref={headerRef}>
                <img alt={'NSP Admin'} src={'/logo.svg'} height={64} width={64}/>
                {
                    !isLogin && (
                        <Stack spacing={2} direction={'row'} alignItems={'center'}>
                            <Button onClick={signOut} color={'warning'} startIcon={<ExitToApp/>}>
                                Log ud
                            </Button>
                        </Stack>
                    )
                }
            </StyledHeader>
            <StyledContainer sx={{ paddingTop: `${isMobile ? 40 : headerHeight}px`, zIndex: 100}}>
                {
                    !isLogin && (
                        <StyledTabBarContainer>
                            <TabBar items={fakeTabs} defaultSelected={locationKey} onChange={handleChangeTab}/>
                        </StyledTabBarContainer>
                    )
                }

                <Container maxWidth={'sm'} sx={{ position: 'relative'}}>
                    {children}
                </Container>
            </StyledContainer>

        </Styled>
    )
}
