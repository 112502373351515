import {Page} from "../Components/Page/Page";
import {Button, CircularProgress, TextField, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import {useNavigate, useLocation, Navigate} from 'react-router-dom';
import {useAuth} from "../Hooks/useAuth";
import {useState} from "react";

export const LoginPage = () => {
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const auth = useAuth();

    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const onSubmit = async (data: { [key: string]: string }) => {
        const { username, password } = data;
        setIsSubmitting(true)
        try {
            await auth.signIn(username, password);
            // @ts-ignore
            const from = location.state?.from?.pathname || "/";
            navigate(from, { replace: true });
        } catch (err) {
            setError('password', { message: 'Forkert login. Prøv igen.' })
        } finally {
            setIsSubmitting(false)
        }
    }

    if (auth.isAuthenticated) {
        return <Navigate to={'sessions'} replace />
    }

    return (
        <Page
            sx={{
                maxWidth: 400,
                ['& > form']: {
                    marginTop: 2,
                    ['& > *']: {
                        marginTop: 2
                    }
                }
            }}
        >
            <Typography variant={'h3'}>
                Hej bror, velkommen :)
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    margin="dense"
                    id={'username'}
                    autoFocus={true}
                    {...register('username', { required: true })}
                    label={'Brugernavn'}
                    error={errors?.password}
                    type="text"
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    margin="dense"
                    id={'password'}
                    autoFocus={true}
                    {...register('password', { required: true })}
                    label={'Adgangskode'}
                    type="password"
                    error={errors?.password}
                    helperText={errors?.password?.message}
                    fullWidth
                    variant="outlined"
                />
                <Button variant={'outlined'} size={'large'} disabled={isSubmitting} fullWidth type={'submit'}>
                    { isSubmitting ? <CircularProgress size={18}/> : 'Log ind' }
                </Button>
            </form>
        </Page>
    )
}
