import {Box, BoxProps, styled, Tab, Tabs} from "@mui/material";
import {SvgIconComponent} from "@mui/icons-material";
import { SyntheticEvent, useEffect, useState} from "react";

const Styled = styled(Box)`
  width: 100%;
  padding: 4px;
  position: sticky;
  top: 4px;
  background-color: white;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.05);
  border-radius: 10px;
  border: 1px solid rgba(200,200,200,0.2);
`;

export type TTab = {
    key: string,
    label: string,
    Icon: SvgIconComponent
}

interface ITabBarProps {
    defaultSelected?: string,
    onChange?: (key: string) => void,
    items?: TTab[]
}

export const TabBar = ({ items, defaultSelected, onChange, ...props }: BoxProps<'div', ITabBarProps>) => {

    const [selected, setSelected] = useState(defaultSelected ?? items?.[0]?.key);

    useEffect(() => {
        if (defaultSelected !== selected) {
            setSelected(defaultSelected)
        }
    }, [defaultSelected])

    const handleChange = (e: SyntheticEvent, newValue: any) => {
        setSelected(newValue);
        onChange?.(newValue);
    }

    return (
        <Styled {...props}>
            <Tabs
                value={selected}
                onChange={handleChange}

            >
                {
                    items?.map(el => {
                        const { Icon, key, label } = el;
                        return (
                            <Tab
                                value={key}
                                label={label}
                                icon={<Icon fontSize={'small'}/>}
                                iconPosition="start"
                            />
                        )
                    })
                }

            </Tabs>
        </Styled>
    )


}
