const categoryColors: {[key: number]: any} = {
    0: ['info','Blå'],
    1: ['success', 'Grøn'],
    2: ['warning', 'Gul'],
    3: ['error', 'Rød']
}

export const getColorFromCategory = (value: number) => {
    const [color, label] = categoryColors[(value)] ?? ['info', ''] as ['info' | 'warning' | 'success' | 'error', string];
    return {
        color,
        label
    }
}
