import { useStore } from "../Store/useStore";
import * as api from '../Api';

export const useAuth = () => {

    const {isAuthenticated, setIsAuthenticated} = useStore();

    const signIn = async (username: string, password: string) => {
        try {
            await api.signIn(username, password);
            setIsAuthenticated(true);
            return Promise.resolve();
        } catch (err) {
            setIsAuthenticated(false);
            return Promise.reject();
        }
    };

    const signOut = async () => {
        try {
            await api.signOut();
            return Promise.resolve();
        } catch (err) {
            console.log(err);
        } finally {
            setIsAuthenticated(false);
        }
    }

    return { isAuthenticated, signIn, signOut }
}
