import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as api from '../Api';
import {IUser} from "../Api/Api";

export const useUsers = (
    revalidate: boolean = true
) => {
    const queryClient = useQueryClient();

    const QueryKey = ['users'];

    const users = useQuery(QueryKey, () => api.getAll<IUser>('users'), {
        enabled: true,
    });

    const remove = useMutation(
        (id: string) => api.remove('user', id),
        {
            onMutate: async (id: string) => {

                await queryClient.cancelQueries(QueryKey);

                queryClient.setQueryData<IUser[]>(QueryKey, (prev) => {
                    return prev!.filter((el) => el._id !== id)
                });

                const previousUsers =
                    queryClient.getQueryData<IUser[]>(QueryKey);

                return { previousUsers };
            },
            onError: (err, variables, context) => {
                if (context?.previousUsers) {
                    queryClient.setQueryData<IUser[]>(
                        QueryKey,
                        context.previousUsers
                    );
                }
            },
            onSettled: () => {
                revalidate && queryClient.invalidateQueries(QueryKey);
            },
        }
    );

    const create = useMutation(
        (payload: Omit<IUser, '_id'>) => api.create('user', payload),
        {
            onMutate: async (payload: Omit<IUser, '_id'>) => {

                await queryClient.cancelQueries(QueryKey);

                queryClient.setQueryData<IUser[]>(QueryKey, (prev) => {
                    return [ ...(prev ?? []), { ...payload as IUser }]
                });

                const previousSessions =
                    queryClient.getQueryData<IUser[]>(QueryKey);

                return { previousSessions };
            },
            onError: (err, variables, context) => {
                if (context?.previousSessions) {
                    queryClient.setQueryData<IUser[]>(
                        QueryKey,
                        context.previousSessions
                    );
                }
            },
            onSettled: () => {
                revalidate && queryClient.invalidateQueries(QueryKey);
            },
        }
    );

    const update = useMutation(
        ({ data, id}: {data: Partial<Omit<IUser, '_id'>>, id: string}) => api.update('user', data, id),
        {
            onMutate: async ({ data, id }: {data: Partial<Omit<IUser, '_id'>>, id: string}) => {

                await queryClient.cancelQueries(QueryKey);

                queryClient.setQueryData<IUser[]>(QueryKey, (prev) => {
                    return prev!.map(el => {
                        return el._id === id ? { ...el, ...data } : el;
                    })
                });

                const previousSessions =
                    queryClient.getQueryData<IUser[]>(QueryKey);

                return { previousSessions };
            },
            onError: (err, variables, context) => {
                if (context?.previousSessions) {
                    queryClient.setQueryData<IUser[]>(
                        QueryKey,
                        context.previousSessions
                    );
                }
            },
            onSettled: () => {
                revalidate && queryClient.invalidateQueries(QueryKey);
            },
        }
    );

    const updatePassword = useMutation(
        ({ password, id}: {password: string, id: string}) => api.updatePassword(id, password),
        {
            onSettled: () => {
                revalidate && queryClient.invalidateQueries(QueryKey);
            }
        }
    );

    return {
        users,
        remove,
        update,
        create,
        updatePassword
    };
};
