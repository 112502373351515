import {Page} from "../Components/Page/Page";
import {
    Box,
    Button,
    Card,
    IconButton,
    TextField,
    InputAdornment,
    Fab,
    DialogTitle,
    DialogContent,
    DialogActions, Dialog,
    List, ListItem, ListItemButton, ListItemIcon, Checkbox,
    ListItemText
} from "@mui/material";
import {useUsers} from "../Hooks/useUsers";
import {
    DeleteForeverRounded,
    EditRounded,
    SaveRounded,
    LockRounded,
    AddRounded,
    LibraryMusicRounded
} from "@mui/icons-material";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useSessions} from "../Hooks/useSessions";
import {ITrackApi} from "../Utils/types";
import {SessionSkeleton} from "./SessionsPage";
import { CheckboxList, TCheckboxItem } from "../Components/CheckboxList/CheckboxList";
import {useChecklist} from "../Hooks/useChecklist";

export interface IUserItemProps {
    username?: string,
    password?: string,
    onUpdatePassword: () => void,
    onDelete: () => void,
    onClickTracks: () => void,
    onUpdate: (values: object) => void
}

type FormInputs = {
    username: string
}

const UserItem = ({ username, onUpdatePassword, onUpdate, onClickTracks, onDelete }: IUserItemProps) => {

    const [editing, setEditing] = useState(false)

    const { register, getValues, resetField, formState: { isDirty } } = useForm<FormInputs>({
        defaultValues: { username },
    });

    const handleSave = async () => {
        if (isDirty) {
            const v = getValues();
            onUpdate(v);
            resetField('username', { defaultValue: v.username });
        }
        setEditing(false);
    }

    return (
        <Card sx={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex'}}>
            <TextField
                margin="dense"
                id="username"
                disabled={!editing}
                label="Brugernavn"
                type="text"
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                type={editing ? 'submit' : 'button'}
                                onClick={editing ? handleSave : () => setEditing(p => !p)}
                            >
                                {editing ? <SaveRounded fontSize={'small'} color={'success'} /> : <EditRounded fontSize={'small'} />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                {...register('username')}
            />
            <Box display={'flex'} alignItems={'center'}>
                <Button onClick={onUpdatePassword} startIcon={<LockRounded/>}>
                    Skift kodeord
                </Button>
                <IconButton sx={{ marginLeft: 1, marginRight: 1 }} onClick={onClickTracks}>
                    <LibraryMusicRounded/>
                </IconButton>
                <IconButton color={'error'} onClick={onDelete}>
                    <DeleteForeverRounded/>
                </IconButton>
            </Box>
        </Card>
    )
}

const mapSessions = (sessions: Array<ITrackApi>, accessList: string[], shouldBeEmpty = false) => {
    return sessions?.map(el => {
        return { title: el.title, _id: el._id, isChecked: !shouldBeEmpty && accessList.includes(el._id!) }
    }) ?? []
}

type TEditState = 'creating' | 'updating' | 'togglingTracks';

export const UsersPage = () => {

    const [open, setOpen] = useState(false);

    const { users, updatePassword, update, remove, create } = useUsers();

    const [editState, setEditState] = useState<TEditState | null>(null);
    const [selected, setSelected] = useState(-1);

    const { sessions } = useSessions();

    const { register, getValues } = useForm();

    const { checked, reset: resetChecked, render: renderChecklist } = useChecklist(mapSessions(sessions.data!, users.data?.[selected]?.accessToTracks ?? []));

    const handleUpdatePassword = (id: string) => () => {
        const pw = prompt('Skriv nyt kodeord');
        if (pw) {
            updatePassword.mutate({ id, password: pw})
        }
    }

    const handleRemove = (id: string) => () => {
        if (window.confirm('Er du sikker på at slette?')) {
            remove.mutate(id)
        }
    }

    const handleUpdate = (id: string) => (data: object) => {
        update.mutate({ data, id })
    }

    const handleClickCreate = () => {
        setEditState('creating');
        setOpen(true);
    }

    const handleClose = () => setOpen(false);

    const handleSubmitModal = () => {
        const accessToTracks = checked.map(el => {
            return el._id
        })
        if (editState === 'creating') {
            const v = getValues();
            const payload = {
                ...v,
                accessToTracks
            };
            create.mutate(payload);
        }
        else if (editState === 'togglingTracks' && users!.data?.[selected]?._id) {
            update.mutate({ data: { accessToTracks }, id: users!.data[selected]._id })
        }
        setEditState(null);
        setOpen(false);
        setSelected(-1);
        resetChecked();
    }

    const handleClickTracks = (index: number) => () => {
        setSelected(index);
        setEditState('togglingTracks');
        setOpen(true);
    }

    const renderDialogTitle = (state: TEditState, ...params: any[]) => {
        switch (state) {
            case 'creating':
                return 'Opret ny';
            case 'togglingTracks':
                return 'Vælg sessioner denne bruger har adgang til'
            default:
                return 'Redigér'

        }
    }

    return (
        <Page sx={{
            ['& > *']: {
                marginBottom: 2
            }
        }}>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ fontSize: '1.3em'}}>{renderDialogTitle(editState!)}</DialogTitle>
                <DialogContent sx={{
                    ['& > .MuiTextField-root']: {
                        marginBottom: 2,
                        marginTop: 0,
                        ['&:first-child']: {
                            marginTop: 2
                        }
                    }
                }}>

                    {

                        editState === 'creating' && (
                            <>
                                <TextField
                                    margin="dense"
                                    id={'username'}
                                    autoFocus={true}
                                    {...register('username')}
                                    label={'Brugernavn'}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                />

                                <TextField
                                    margin="dense"
                                    id={'password'}
                                    {...register('password')}
                                    label={'Adgangskode'}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                />

                            </>
                        )

                    }

                    {
                        renderChecklist()
                    }

                </DialogContent>
                <DialogActions>
                    <Button color={'error'} onClick={handleClose}>Annullér</Button>
                    <Button onClick={handleSubmitModal}>Gem</Button>
                </DialogActions>
            </Dialog>


            {
                users!.data?.map((el, i) => (
                    <UserItem
                        password={el.password}
                        username={el.username}
                        onUpdatePassword={handleUpdatePassword(el._id)}
                        onDelete={handleRemove(el._id)}
                        onUpdate={handleUpdate(el._id)}
                        onClickTracks={handleClickTracks(i)}
                    />
                )) ?? <SessionSkeleton/>
            }

            <Fab color={'primary'} onClick={handleClickCreate} sx={{ position: 'fixed', bottom: 40, right: 40}}>
                <AddRounded/>
            </Fab>
        </Page>
    )
}
