import axios, { IShallowSuccessResponse } from './index';

export const signIn = async (
    username: string,
    password: string
) => {
    if (typeof process.env.REACT_APP_API_UPLOAD === 'undefined') {
        throw new Error(
            'Environment variable REACT_APP_API_UPDATE is not defined'
        );
    }

    const { data } = await axios.post<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_AUTH_SIGNIN}`,
        {
            username,
            password
        }
    );

    return data;
};

export const signOut = async () => {
    if (typeof process.env.REACT_APP_API_AUTH_SIGNOUT === 'undefined') {
        throw new Error(
            'Environment variable REACT_APP_API_UPDATE is not defined'
        );
    }

    const { data } = await axios.post<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_AUTH_SIGNOUT}`
    );

    return data;
};
