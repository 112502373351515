import {useState} from "react";
import {Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";

export type TCheckboxItem = {
    _id: string,
    title: string,
    isChecked: boolean
}

export const CheckboxList = ({ items, onUpdateChecked }: { items: TCheckboxItem[], onUpdateChecked: (newItems: TCheckboxItem[]) => void}) => {

    const [_items, setItems] = useState<TCheckboxItem[]>(items)

    const handleUpdate = (index: number) => () => {
        setItems(prev => {
            const _new = prev.map((el, i) => {
                return i === index ? { ...el, isChecked: !el.isChecked } : el;
            });
            onUpdateChecked(_new);
            return _new;
        });
    }
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {_items.map(({title, isChecked}, i ) => {
                const labelId = `checkbox-list-label-${i}`;

                return (
                    <ListItem
                        key={labelId}
                        disablePadding
                    >
                        <ListItemButton role={undefined} onClick={handleUpdate(i)} dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={isChecked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={title} />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
}
