import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as api from '../Api';
import {ITrackApi} from "../Utils/types";

export const useSessions = (
    revalidate: boolean = true
) => {
    const queryClient = useQueryClient();

    const QueryKey = ['sessions'];

    const sessions = useQuery(QueryKey, () => api.getAll<ITrackApi>('sessions'), {
        enabled: true,
    });

    const remove = useMutation(
        (id: string) => api.remove('session', id),
        {
            onMutate: async (id: string) => {

                await queryClient.cancelQueries(QueryKey);

                queryClient.setQueryData<ITrackApi[]>(QueryKey, (prev) => {
                    return prev!.filter((el) => el._id !== id)
                });

                const previousSessions =
                    queryClient.getQueryData<ITrackApi[]>(QueryKey);

                return { previousSessions };
            },
            onError: (err, variables, context) => {
                if (context?.previousSessions) {
                    queryClient.setQueryData<ITrackApi[]>(
                        QueryKey,
                        context.previousSessions
                    );
                }
            },
            onSettled: () => {
                revalidate && queryClient.invalidateQueries(QueryKey);
            },
        }
    );

    const create = useMutation(
        (payload: Omit<ITrackApi, '_id'>) => api.create('session', payload),
        {
            onMutate: async (payload: Omit<ITrackApi, '_id'>) => {

                await queryClient.cancelQueries(QueryKey);

                queryClient.setQueryData<ITrackApi[]>(QueryKey, (prev) => {
                    return [ ...(prev ?? []), { ...payload as ITrackApi }]
                });

                const previousSessions =
                    queryClient.getQueryData<ITrackApi[]>(QueryKey);

                return { previousSessions };
            },
            onError: (err, variables, context) => {
                if (context?.previousSessions) {
                    queryClient.setQueryData<ITrackApi[]>(
                        QueryKey,
                        context.previousSessions
                    );
                }
            },
            onSettled: () => {
                revalidate && queryClient.invalidateQueries(QueryKey);
            },
        }
    );

    const update = useMutation(
        ({ data, id}: {data: Partial<Omit<ITrackApi, '_id'>>, id: string}) => api.update('session', data, id),
        {
            onMutate: async ({ data, id }: {data: Partial<Omit<ITrackApi, '_id'>>, id: string}) => {

                await queryClient.cancelQueries(QueryKey);

                queryClient.setQueryData<ITrackApi[]>(QueryKey, (prev) => {
                    return prev!.map(el => {
                        return el._id === id ? { ...el, ...data } : el;
                    })
                });

                const previousSessions =
                    queryClient.getQueryData<ITrackApi[]>(QueryKey);

                return { previousSessions };
            },
            onError: (err, variables, context) => {
                if (context?.previousSessions) {
                    queryClient.setQueryData<ITrackApi[]>(
                        QueryKey,
                        context.previousSessions
                    );
                }
            },
            onSettled: () => {
                revalidate && queryClient.invalidateQueries(QueryKey);
            },
        }
    );

    return {
        sessions,
        remove,
        update,
        create
    };
};
