import {CheckboxList, TCheckboxItem} from "../Components/CheckboxList/CheckboxList";
import {useState} from "react";

interface IUseChecklistProps {
    items: TCheckboxItem[],
    onUpdateChecked: (newItems: TCheckboxItem[]) => void
}

export const useChecklist = (items: IUseChecklistProps['items'])  => {

    const [ checked, setChecked ] = useState<TCheckboxItem[]>([]);

    const handleUpdate = (newItems: TCheckboxItem[]) => {
        setChecked(newItems.filter(el => el.isChecked))
    }

    const reset = () => setChecked([]);

    const render = () => <CheckboxList items={items} onUpdateChecked={handleUpdate}/>

    return {
        checked,
        render,
        reset
    }

}
