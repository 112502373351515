import axios, { IShallowSuccessResponse } from './index';

export const upload = async (
     fileType: 'track' | 'artwork',
     formData: FormData,
     query: {
       [key: string]: string | number
     },
     renderProgress?: (progress: number) => void,
     abortController?: AbortController
) => {
    if (typeof process.env.REACT_APP_API_UPLOAD === 'undefined') {
        throw new Error(
            'Environment variable REACT_APP_API_UPDATE is not defined'
        );
    }

    try {
        const { data } = await axios.post<{ url: string, id?: string }>(
            `${process.env.REACT_APP_API_UPLOAD}/${fileType}`,
            formData,
            {
                signal: abortController?.signal,
                params: {
                    ...query
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    renderProgress?.(percentCompleted);
                }
            }
        );
        return data;
    } catch (err) {
        return <{[key: string]: string}>{}
    }
};

export const deleteUpload = async (
    fileType: 'track' | 'artwork',
    sessionId: string
) => {
    if (typeof process.env.REACT_APP_API_UPLOAD === 'undefined') {
        throw new Error(
            'Environment variable REACT_APP_API_UPDATE is not defined'
        );
    }

    try {
        await axios.delete<IShallowSuccessResponse>(
            `${process.env.REACT_APP_API_UPLOAD}/${fileType}/${sessionId}`

        );
    } catch (err) {}
};
