import {ITrackApi} from "../../Utils/types";
import {Box, Card, Chip, IconButton, styled, Tooltip, Typography} from "@mui/material";
import {AccessTimeRounded, DeleteForeverRounded, WarningRounded} from "@mui/icons-material";
import {useMemo} from "react";
import {getColorFromCategory} from '../../Utils/helpers';

const Styled = styled(Card)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    cursor: 'pointer',
    position: 'relative',
    ['&:hover']: {
        backgroundColor: 'rgb(253,253,253)'
    }
})

const pad = (num: number) => {
    return num < 10 ? `0${num}` : num;
}

const formatDuration = (duration: number) => {
    const mins = Math.floor(duration / 60);
    const secs = Math.floor(duration % 60);
    return `${mins}${secs > 0 ? `:${pad(secs)}`: ''}`
}

export const SessionItem = (props: ITrackApi & { onClick: () => void, onDelete: (evt: any) => void }) => {

    const { title, category, excerpt, track, artist, description, duration, artwork, onClick, onDelete } = props;

    const isValidAndThusOnline = useMemo(() => {
        return title && excerpt && (category != null || typeof category === "number") && description && duration && artwork && track && artist
    }, [props])

    const { color: categoryColor, label: categoryLabel } = getColorFromCategory(category);

    return (
        <Styled onClick={onClick}>
            <img width={80} height={80} src={artwork} style={{ opacity: isValidAndThusOnline ? 1 : 0.4  }}/>
            {
                !isValidAndThusOnline && (
                    <Tooltip title="Denne session kan ikke være tilgængelig i appen, før alle data er udfyldt.">
                        <Box display={'flex'} alignItems={'center'} position={'absolute'} top={12} right={12} fontSize={'0.75em'} color={'var(--color-blue)'}>
                            Mangler data <WarningRounded fontSize={'small'} sx={{ marginLeft: 0.5}}/>
                        </Box>
                    </Tooltip>
                )
            }
            <Box sx={{ marginLeft: 2, flexGrow: '1', opacity: isValidAndThusOnline ? 1 : 0.4 }}>
                <Typography variant={'h5'}>
                    {title}
                </Typography>
                <Typography sx={{ fontSize: '0.88em'}}>{description}</Typography>
                <Box display={'flex'} alignItems={'center'} fontSize={'0.75em'} marginTop={1}>
                    {
                        Boolean(categoryLabel) && Boolean(categoryColor) && (
                            <Chip sx={{ marginRight: 1.5}} size={'small'} color={categoryColor} label={categoryLabel}/>
                        )
                    }

                    <Box display={'flex'} alignItems={'center'}>
                        <AccessTimeRounded fontSize={'small'} sx={{ marginRight: 1}}/> {formatDuration(duration)} mins
                    </Box>
                </Box>

            </Box>
            <IconButton color={'error'} onClick={onDelete}>
                <DeleteForeverRounded />
            </IconButton>
        </Styled>
    )
}
