import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {theme} from "./Utils/theme";
import {ThemeProvider} from "@mui/material";
import { QueryClient, QueryClientProvider } from 'react-query';
const container = document.getElementById('root') as HTMLElement;

/*(async () => {
   return;
    if (process.env.NODE_ENV === 'development') {
       const { worker } = require('./Mocks/browser');

       await worker.start({
           onUnhandledRequest: 'warn', // Change this to 'warn', when you want to be notified about requests passing through unmocked (but be aware of the proxy in setupProxy.js),
           serviceWorker: {
               url: `${process.env.PUBLIC_URL}/mockServiceWorker.js`,
           }
       });
   }
 })();*/

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5, // Default stale time of 10 minutes
            retry: false, // We rarely have timeout/sloppy connections
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </QueryClientProvider>
    </React.StrictMode>,
container);
