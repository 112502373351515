import {useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {Dropzone} from "../Dropzone/Dropzone";
import {AddPhotoAlternateRounded, LibraryMusicRounded, SaveRounded} from "@mui/icons-material";
import {ITrackApi} from "../../Utils/types";
import isEmpty from 'lodash.isempty';
import { getColorFromCategory } from "../../Utils/helpers";
import {useUsers} from "../../Hooks/useUsers";

const inputs = [
    {
        key: 'title',
        label: 'Titel'
    },
    {
        key: 'description',
        label: 'Beskrivelse',
        props: {
            multiline: true,
            rows: 2
        },
    },
    {
        key: 'artist',
        label: 'Forfatter'
    },
    {
        key: 'excerpt',
        props: {
            multiline: true,
            rows: 4
        },
        label: 'Lang beskrivelse'
    },
    {
        key: 'category',
        label: 'Kategori',
        type: 'select',
        options: [
            {
                label: 'Blå',
                value: 0
            },
            {
                label: 'Grøn',
                value: 1
            },
            {
                label: 'Gul',
                value: 2
            },
            {
                label: 'Rød',
                value: 3
            }
        ]
    }
];

type TData = Partial<Omit<ITrackApi, '_id'>>;

interface ISessionDialogProps {
    onClose: () => void,
    open: boolean,
    onSubmit: <T extends {[key: string]: string | number}>(payload: T) => void,
    data: TData | undefined,
    refetchHandler: () => void
}


export const SessionDialog = ({ onClose, data, open, refetchHandler, onSubmit }: ISessionDialogProps) => {

    const { register, unregister, getValues, formState } = useForm<TData>({
        defaultValues: {
            ...data
        }
    });
    const [ uploads, setUploads ] = useState<{[key: string | 'track' | 'artwork']: string}>({});
    const [ _props, setProps ] = useState<{[key: string]: string | number}>({});
    const [isUploading, setIsUploading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const { users } = useUsers();

    const reset = () => {
        setUploads({});
        setProps({});
        setIsUploading(false);
        setIsDirty(false);
    }

    //const { checked, reset: resetChecklist, render: renderChecklist } = useChecklist(mapUser(users))

    useEffect(() => {
        const seemsDirty = !isEmpty(formState.dirtyFields) || !isEmpty(uploads)
        setIsDirty(seemsDirty)
    }, [formState, uploads])

    useEffect(() => {
        if (open) {
            inputs.map(el => unregister(el.key))
        } else {
            reset();
        }
    }, [open])

    const handleSubmit = () => {
        const _v = getValues();
        onSubmit({ ..._v, ...uploads, ..._props });
    }

    const handleUploadSuccess = ({ url, duration }: { url: string, duration?: number }, type: 'artwork' | 'track') => {
        setUploads(prev => {
            return { ...prev, [type]: url }
        });
        if (duration) {
            setProps(prev => {
                return { ...prev, duration }
            })
        }
        setIsUploading(false);
    }

    const handleClose = () => {
        if (isDirty) {
            if (!window.confirm('Du har foretaget ændringer, er du sikker på at annullere?')) {
                return;
            }
        }
        onClose();
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle sx={{ fontSize: '1.3em'}}>{data?.['title'] ?? 'Opret ny'}</DialogTitle>
            <DialogContent sx={{
                ['& > .MuiTextField-root']: {
                    marginBottom: 2,
                    marginTop: 0,
                    ['&:first-child']: {
                        marginTop: 2
                    }
                }
            }}>
                {
                    inputs.map((el, _i) => {
                        return ( el.type === 'select' ?
                                <FormControl fullWidth>
                                    <InputLabel id={el.key}>{el.label}</InputLabel>
                                    <Select
                                        labelId={el.key}
                                        id={`${el.key}-select`}
                                        label={el.label}
                                        defaultValue={data?.[el.key]}
                                        {...register(el.key)}
                                        renderValue={(value) => {
                                            const { color, label } = getColorFromCategory(value);
                                            if (!color || !label) return null
                                            return <Chip color={getColorFromCategory(value).color} key={value} label={el.options[value].label} />
                                        }}
                                    >
                                        {
                                            el.options.map(option => {
                                                return (
                                                    <MenuItem value={option.value}>{option.label}</MenuItem>
                                                )
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                :
                                <TextField
                                    margin="dense"
                                    id={el.key}
                                    autoFocus={_i == 0 && !data?.[el.key]}
                                    defaultValue={data?.[el.key]}
                                    label={el.label}
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    { ...el.props }
                                    {...register(el.key)}
                                />
                        )
                    })
                }

                <Dropzone
                    onSuccess={handleUploadSuccess}
                    onUploadStatusChange={setIsUploading}
                    onDelete={refetchHandler}
                    Icon={AddPhotoAlternateRounded}
                    id={(data?.['_id'])!}
                    fileType={'artwork'}
                >
                    {
                        (uploads?.['artwork'] ?? data?.['artwork']) && (
                            <img alt={'Artwork'} height={'120'} src={uploads?.['artwork'] ?? data?.['artwork'] ?? ''}/>
                        )
                    }
                </Dropzone>

                <Dropzone
                    onSuccess={handleUploadSuccess}
                    onUploadStatusChange={setIsUploading}
                    onDelete={refetchHandler}
                    dragLabel={'lydfil'}
                    fileType={'track'}
                    id={(data?.['_id'])!}
                    Icon={LibraryMusicRounded}>
                    {
                        (uploads?.['track'] ?? data?.['track']) && (
                            <audio controls>
                                <source src={uploads?.['track'] ?? data?.['track']} type="audio/mpeg"/>
                            </audio>
                        )
                    }
                </Dropzone>

            </DialogContent>
            <DialogActions>
                { isDirty && <Button disabled={isUploading} color={'error'} onClick={onClose}>Annullér</Button>}
                <Button
                    disabled={isUploading}
                    onClick={isDirty ? handleSubmit : handleClose}
                    startIcon={isDirty ? <SaveRounded/> : null}
                >
                    {isDirty ? 'Gem' : 'Luk'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
