import {ITrackApi} from "../Utils/types";
import axios, { IShallowSuccessResponse } from './index';

export interface IUser {
    username?: string,
    password?: string,
    accessToTracks: string[],
    _id: string
}

export const getAll = async <T>(
    modelType: 'users' | 'sessions'
) => {
    if (typeof process.env.REACT_APP_API_GET_ALL === 'undefined') {
        throw new Error(
            'Environment variable REACT_APP_API_GET_ALL is not defined'
        );
    }

    const { data } = await axios.get<Array<T>>(
        `${process.env.REACT_APP_API_GET_ALL}/${modelType}`,
        {
        }
    );

    return data;
};

export const remove = async (
    modelType: 'user' | 'session',
    modelId: string
) => {
    if (typeof process.env.REACT_APP_API_REMOVE === 'undefined') {
        throw new Error(
            'Environment variable REACT_APP_API_REMOVE is not defined'
        );
    }

    const { data } = await axios.delete<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_REMOVE}/${modelType}/${modelId}`
    );

    return data;
};

export const create = async (
    modelType: 'user' | 'session',
    payload: Omit<ITrackApi, '_id'>
) => {
    if (typeof process.env.REACT_APP_API_CREATE === 'undefined') {
        throw new Error(
            'Environment variable REACT_APP_API_CREATE is not defined'
        );
    }

    const { data } = await axios.post<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_CREATE}/${modelType}`,
        payload
    );

    return data;
};

export const update = async (
    modelType: 'user' | 'session',
    payload: Partial<Omit<ITrackApi, '_id'>>,
    id?: string
) => {
    if (typeof process.env.REACT_APP_API_UPDATE === 'undefined') {
        throw new Error(
            'Environment variable REACT_APP_API_UPDATE is not defined'
        );
    }

    const { data } = await axios.put<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_UPDATE}/${modelType}/${id!}`,
        payload
    );

    return data;
};
