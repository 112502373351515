import {Page} from "../Components/Page/Page";
import {useSessions} from "../Hooks/useSessions";
import {SessionItem} from "../Components/SessionItem/SessionItem";
import {useState} from "react";
import {SessionDialog} from "../Components/SessionDialog/SessionDialog";
import {Box, Fab, Skeleton} from "@mui/material";
import {AddRounded} from "@mui/icons-material";

export const SessionSkeleton = () => {
    return (
        <Box display={'flex'} width={'100%'} alignItems={'center'}>
            <Skeleton sx={{ borderRadius: 1}} variant="rectangular" width={80} height={80} />
            <Box pl={3} width={'100%'}>
                <Skeleton variant="text" width={'100%'} />
                <Skeleton variant="text" width={'80%'} />
                <Skeleton variant="text" width={'60%'} />
            </Box>
        </Box>
    )
}

export const SessionsPage = () => {

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<number>(-1);
    const [creating, setCreating] = useState(false);

    const handleClickOpen = (index: number) => () => {
        setOpen(true);
        setSelected(index);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { sessions, remove, update, create } = useSessions();

    const handleDelete = (index: number, id: string) => (evt: MouseEvent) => {
        evt.stopPropagation();
        if (window.confirm('Er du sikker på at slette?')) {
            remove.mutate(id);
        }
    }

    const handleSubmitModal = (v: object) => {
        if (creating) {
            create.mutate(v);
            setCreating(false);
        }
        else {
            const id = sessions!.data?.[selected]?._id;
            if (id) {
                update.mutate({ data: { ...v }, id })
            }
        }
        setOpen(false);
    }

    const handleClickCreate = () => {
        setSelected(-1);
        setOpen(true);
        setCreating(true)
    }

    return (
        <Page sx={{
            ['& > *']: {
                marginBottom: 2
            }
        }}>

            <SessionDialog
                onClose={handleClose}
                onSubmit={handleSubmitModal}
                refetchHandler={sessions.refetch}
                open={open}
                data={sessions!.data?.[selected]}
            />

            {

                sessions!.data?.map((el, i) => {
                    return <SessionItem key={el._id} onDelete={handleDelete(i, el._id!)} onClick={handleClickOpen(i)} {...el}/>
                }) ?? <SessionSkeleton/>

            }

            <Fab color={'primary'} onClick={handleClickCreate} sx={{ position: 'fixed', bottom: 40, right: 40}}>
                <AddRounded/>
            </Fab>

        </Page>
    )
}
