import { useStore } from "../Store/useStore";
import axios from "axios";
import { toast } from "react-toastify";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL!,
    withCredentials: true
});

api.interceptors.response.use((response) => response, (err) => {
    if (err.response.status === 403) {
        useStore.setState({ isAuthenticated: false });
        toast.warning('Du er blevet logget ud.', {
            hideProgressBar: true,
            closeButton: false,
            autoClose: 2000,
            position: "bottom-center"
        })
    }
    return Promise.reject(err);
});

export default api;

export { getAll, remove, create, update } from './Api';
export { updatePassword } from './User'
export { upload, deleteUpload } from './Upload';
export { signIn, signOut } from './Auth'

export interface IShallowSuccessResponse {
    status: 'success'
}
