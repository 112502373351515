import React from 'react';
import {BrowserRouter, Navigate, Routes, Route} from "react-router-dom";
import {UsersPage} from "./Pages/UsersPage";
import {LoginPage} from "./Pages/LoginPage"
import {SessionsPage} from "./Pages/SessionsPage";
import { AppShell } from "./Components/AppShell/AppShell";
import {PrivateRoute} from "./Utils/PrivateRoute";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const App = () => {

    return (
      <BrowserRouter>
          <ToastContainer/>
          <AppShell>
              <Routes>
                  <Route path={'login'} element={<LoginPage/>}/>
                  <Route path={'sessions'} element={
                      <PrivateRoute>
                          <SessionsPage/>
                      </PrivateRoute>
                  }/>
                  <Route path={'users'} element={
                      <PrivateRoute>
                          <UsersPage/>
                      </PrivateRoute>
                  }/>
                  <Route path={'*'} element={<Navigate to={'/sessions'}/>}/>
              </Routes>
          </AppShell>
      </BrowserRouter>
  );
}

export default App;
