import {Box, styled} from "@mui/material";
import React, {ComponentPropsWithoutRef} from "react";

const StyledPage = styled(Box)(({theme}) => ({
    margin: "40px auto 40px auto",
    maxWidth: theme.breakpoints.up('xl'),
    width: "100%",
    position: "relative"
})) as typeof Box;

export const Page = ({ children, ...props }: ComponentPropsWithoutRef<typeof Box>) => {
    return <StyledPage {...props}>{ children }</StyledPage>
}
