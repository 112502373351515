import axios, { IShallowSuccessResponse } from './index';

export interface IUser {
    username?: string,
    password?: string,
    accessToTracks: [string],
    _id: string
}

export const updatePassword = async (
    id: string,
    password: string
) => {
    if (typeof process.env.REACT_APP_API_UPDATE === 'undefined') {
        throw new Error(
            'Environment variable REACT_APP_API_UPDATE is not defined'
        );
    }

    const { data } = await axios.put<IShallowSuccessResponse>(
        `${process.env.REACT_APP_API_UPDATE}/user/${id}/password`,
        { password }
    );

    return data;
};
